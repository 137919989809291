<template>
  <v-card elevation="0" rounded="xl">
    <v-container>
      <v-list-item>
        <v-list-item-avatar size="40">
          <v-img src="https://hisolver-files.s3.amazonaws.com/images/anna-2023-12-21.png" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            Anna
          </v-list-item-title>

          <v-list-item-subtitle>
            Insight builder
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <v-container class="py-3">
            <v-row align="center">
              <span class="live-indicator"></span>
            </v-row>
          </v-container>
        </v-list-item-icon>
      </v-list-item>

      <v-card-text
        v-html="welcomeText"
      ></v-card-text>

      <v-card-actions>
        <v-btn
          block
          color="primary"
          style="border-radius: 12px;"
          @click="$emit('openChat')"
        >
          open chat
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    welcomeText: `
      This sketch started as an attempt at giving a simpler proof to the
      Fundamental Theorem of Algebra, and ended giving more general and elegant results.
      The purpose of this exhibition is to guide you through understanding of a version of the proof, independent of your background.

      If you want to check out other versions of the proof (and learn more math), reach out to us at <a class="primary--text">anna@hisolver.com</a>. Enjoy! </br>

      O.O.; Y.K.; A.B.
    `,
  })
}
</script>