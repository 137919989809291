<script>
import API from '@api'
import { shuffle } from 'lodash'
import { startOfToday, endOfToday } from 'date-fns'
import { jsonStructure } from '@utils'
import { MediaURL } from '@components'
const { VUE_APP_LANGCHAIN_API } = process.env

export default {
  /**
   * Encapsulates the logic of the callAnna function within a Promise.
   * 
   * @param {array} conversation - The conversation context.
   * @param {object} memory - The memory object.
   * @param {array} tools - Array of tools.
   * @param {string} type - Type of the media.
   * @param {object} message - The message object.
   * @param {object} solution - The solution object.
   * @returns {Promise} - A Promise that resolves with the result of the operation.
   */
  async callAnna(conversation, memory, assistant_name, tools, type, message, question, solution) {
    return new Promise(async (resolve, reject) => {
      try {
        this.annaThinking = true
        this.annaStreaming = true

        let annaMsg;
        let pdfRagKeys = this.contents.map(({media}) => media).filter((_, index) => index < 20);
        
        let langRes;

        // Pdf case
        const pdfQuery = pdfRagKeys.map(key => `pdf/${key}.pdf`)
        const indexNames =  this.langChain || (this.rag && pdfRagKeys.length > 0) ?
          this.fetchContext ? pdfQuery : [`pdf/${this.filename}`] : []

        const query = this.context ? `
          Respond to the student according to the following script:
          ${this.context}

          ${message.content}
        ` : message.content

        langRes = await fetch(`${VUE_APP_LANGCHAIN_API}/hisolver_llm_call`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: false,
          body: JSON.stringify({
            index_names: indexNames,
            username: this.myUsername,
            user_name: this.myName,
            assistant_name,
            query,
            chat_history: memory,
            msg_type: type,
            model_number: process.env.NODE_ENV === 'production' ? 2 : 2
          })
        })

        const reader = langRes.body.getReader();

        let botType = 'anna_msg'
        // other types include persona, etc

        // set the message
        annaMsg = {
          STREAMING: true, // state variable; not saved to the database;
          type: botType,
          room: this.room.id,
          // conversation: conversation.id,
          sender: this.annaSender,
          profile: this.anna.id,
          content: ''
        }

        this.messages.push({
          ...annaMsg,
          createdAt: (new Date()).toISOString()
        })

        const msgIndex = this.messages.length - 1

        // start reading the stream
        let loop = true;
        const decoder = new TextDecoder("utf-8", { stream: true });
        let target = false
        let langJson = '';

        /*
        * TODO: change the structure of the loop to add every few sentences
        * paragraphs instead of the continuous streaming. 
        * That should help fix a bug in safari where scroll bottom is
        * messed up.
        */
        this.annaThinking = false
        while(loop) {
          const {done, value} = await reader.read();

          if(done) {
            loop = false;

            setTimeout(() => {
              this.scrollBottom()
              this.stickyBottom = true
            }, 400)

            if (type === 'test_trigger') this.annaStreaming = false

          } else {
            const chunkAsString = decoder.decode(value);

            // CALL FUNCTIONS
            if (target) {
              // Now used only for the pdf case, if the stop signal u0003
              // comes before the end of the string (so this condition is a fallback)
              langJson += chunkAsString;

            } else if (chunkAsString.includes('\u0003')) {
              target = true; 
              if (chunkAsString != '\u0003')
                langJson += chunkAsString.replace('\u0003', ''); // for the PDF Rag use case
            
            } else this.messages[msgIndex].content += this.replaceLatexDelimiters(chunkAsString);

            this.scrollBottom()
            // END CALL FUNCTIONS

            // force stop the streaming at the user command
            if (this.stopStreaming) {
              loop = false
              this.annaStreaming = false
              this.stopStreaming = false
              this.messages[msgIndex].content += '🫠🔥🫠🔥'
              reader.cancel();
            }
          }
        }

        // deletes the streaming that was set before
        delete this.messages[msgIndex]['STREAMING']

        resolve();

      } catch (error) {
        reject(error);
      }
    });
  }
}
</script>