<template>
  <v-app-bar
    app
    dark
    fixed
    height="60"
    elevation="0"
    clipped-left
    clipped-right
    color="transparent"
  >
    <v-row align="center" justify="space-between">
      <v-col cols="4">
        <v-row
          justify="start"
          align="center"
        >
          <v-btn 
            icon
            large
            color="white"
            v-on="on"
          >
            <v-icon>
              {{ mdiCreation }}
            </v-icon>
          </v-btn>

          <v-toolbar-title class="clickable">
            <div
              style="font-size: 40px;"
              class="font-weight-black pointer"
            >
              Insight Lab
            </div>
          </v-toolbar-title>

          <v-btn 
            icon
            large
            color="white"
            v-on="on"
          >
            <v-icon>
              {{ mdiCreation }}
            </v-icon>
          </v-btn>
        </v-row>
      </v-col>

      <v-col cols="4">
        <v-row justify="end" class="px-4">
          <v-btn
            color="primary"
            style="border-radius: 12px;"
          >
            <v-icon left>
              {{ mdiDiamond }}
            </v-icon>
            amethyst
            <v-icon right>
              {{ mdiDiamond }}
            </v-icon>
          </v-btn>

          <div class="mx-2"></div>
          
          <v-avatar :size="40">
            <v-img src="https://bkt-hack-mathchat.s3.amazonaws.com/amethyst_aug19.png">
              <template v-slot:placeholder>
                <v-skeleton-loader
                  class="mx-auto"
                  height="40"
                  width="40"
                  type="image"
                />
              </template>
            </v-img>
          </v-avatar>
        </v-row>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import {
  mdiCreation,
  mdiArrowRight,
  mdiCreationOutline,
  mdiDiamond,
} from '@mdi/js'

export default {
  created() {
    this.tooltipsOn = this.settings.tooltipsOn
  },

  data() {
    return {
      mdiDiamond,
      mdiArrowRight,
      mdiCreation,
      mdiCreationOutline,
      showEditProfile: false,
      setFocusOn: false,
      tooltipsOn: true,
      bellOn: false
    }
  },

  watch: {
    settings: {
      deep: true,
      handler: function(newSettings) {
        this.tooltipsOn = newSettings.tooltipsOn
      }
    }
  },

  computed: {
    ...mapGetters({
      showChat: 'showChat',
      showSlides: 'showSlides',
      settings: 'settings'
    }),
    
    toolbarColor() {
      return !this.$vuetify.theme.dark
        ? 'primary'
        : 'grey darken-4'
    }
  },

  methods: {
    ...mapMutations({
      OPEN_CHAT: 'OPEN_CHAT',
      SET_SLIDES: 'SET_SLIDES',
      SET_DARK_THEME: 'setDarkTheme'
    }),

    goToWebsite() {
      window.location.href = 'https://www.hisolver.com';
    },

    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.SET_DARK_THEME(this.$vuetify.theme.dark)
    }
  }
}
</script>

<style scoped>
::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>