export default {
  namespaced: true,

  state: {
    mini: true,
    navOn: true,
    navTemp: false,
    navTempOn: false,
    jobFilterDrawer: false,
    groupFilterDrawer:false,
    messageDrawer: false,
    showError: false,
    errorMessage: '',
    libraryNav: 0,
    bottomAudioOn: false
  },

  getters: {
    mini: ({ mini }) => mini,
    navOn: ({ navOn }) => navOn,
    navTemp: ({ navTemp }) => navTemp,
    navTempOn: ({ navTempOn }) => navTempOn,
    jobFilterDrawer: ({ jobFilterDrawer }) => jobFilterDrawer,
    groupFilterDrawer: ({ groupFilterDrawer }) => groupFilterDrawer,
    messageDrawer: ({ messageDrawer }) => messageDrawer,
    showError: ({ showError }) => showError,
    errorMessage: ({ errorMessage }) => errorMessage,
    libraryNav: ({ libraryNav }) => libraryNav,
    bottomAudioOn: ({ bottomAudioOn }) => bottomAudioOn
  },

  mutations: {
    setLibraryNav: (state, libraryNav) => state.libraryNav = libraryNav,
    mini: state => state.mini = !state.mini,
    navOn: (state, payload) => state.navOn = payload,
    navTemp: state => state.navTemp = !state.navTemp,
    navTempOn: (state, payload) => state.navTempOn = payload,
    bottomAudioOn: (state) => state.bottomAudioOn = !state.bottomAudioOn,
    toggleJobFilterDrawer: state => {
      state.jobFilterDrawer = !state.jobFilterDrawer
    },
    toggleGroupFilterDrawer: state => {
      state.groupFilterDrawer = !state.groupFilterDrawer
    },
    toggleMessageDrawer: (state, payload) => {
      if (typeof payload == 'boolean') {
        state.messageDrawer = payload
      } else {
        state.messageDrawer = !state.messageDrawer
      }
    },
    toggleShowError: state => {
      state.showError = !state.showError
    },
    errorMessage: (state, payload) => {
      state.errorMessage = payload.errorMessage
    }
  }
}