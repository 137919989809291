import { withLayout } from '@utils'
import ContentChat from '@layouts/ContentChat'

const MathChat = () => import('@views/MathChat')

export default {
  path: '',
  component: {
    render: h => h('router-view')
  },
  children: [
    {
      path: '',
      name: 'home',
      component: withLayout(MathChat, ContentChat),
      meta: {
        locked: true,
        app: 'mathchat'
      }
    }
  ]
}
