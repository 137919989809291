import { FILE_CHUNK_SIZE } from '@utils/constants'
import Axios from 'axios'
import API from '@api'

async function uploadParts(file, urls) {
  const axios = Axios.create()
  delete axios.defaults.headers.put['Content-Type']

  const keys = Object.keys(urls)
  const promises = []

  for (const indexStr of keys) {
    const index = parseInt(indexStr)
    const start = (index - 1) * FILE_CHUNK_SIZE
    const end = index * FILE_CHUNK_SIZE
    const blob = index < keys.length
      ? file.slice(start, end)
      : file.slice(start)
    const withCredentials = process.env.NODE_ENV === 'production'
    promises.push(axios.put(urls[index], blob, { withCredentials }))
  }

  const resParts = await Promise.all(promises)
  return resParts.map((part, index) => {
    const idx = index + 1
    return {
      ETag: part.headers.etag,
      PartNumber: idx
    }
  })
}

const s3MultipartUpload = ({ namespace, filename, numOfParts, file }) => {
  return new Promise((resolve, reject) => {
    API().get(
      '/s3/initiateMultipartUpload',
      {
        params: {
          namespace,
          filename,
          numOfParts
        }
      }
    )
    .then(async data => {
      const { urls, actionId, uploadId } = data
      const parts = await uploadParts(file, urls)
      API().get(
        '/s3/completeMultipartUpload',
        {
          params: {
            uploadId,
            parts,
            actionId,
            filename,
            namespace
          }
        }
      )
      .then(resolve)
      .catch(reject)
    })
    .catch(reject)
  })
}

export default s3MultipartUpload