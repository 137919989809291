
<template>
  <div :style="`position: relative; width: 100%; height: ${outerHeight}px;`">
    <!--
      We separate the messages container from the editorjs textarea in different layers.
      The purpose is to have the textarea expand upwards when its input is multiple lines.

      The height here allows us to center the images of the container
    -->
    <v-container
      :class="containerClass"
      :style="`height: ${height}px; position: absolute; z-index: 1;`"
    >
      <v-btn
        v-if="showScrollDown"
        fab
        dark
        small
        :color="scrollDownColor"
        :style="`
          position: absolute;
          z-index: 3;
          right: 30px;
          bottom: 12px;
        `"
        @click="scrollBottom(true)"
      >
        <v-icon dark>
          {{ mdiArrowDown }}
        </v-icon>
      </v-btn>

      <v-container
        id="chatContainer"
        ref="chatContainer"
        v-scroll_bottom
        :class="{
          'hs-custom-scroll px-4 pr-8 pl-6': true,
          'fill-height': messages.length === 0
        }"
        :style="`scroll-behavior: ${smoothScroll ? 'smooth' : 'auto'};
          max-height: ${height - 84}px;
          overflow-y: auto;
          overflow-x: hidden;`"
        @wheel="handleWheel"
        @scroll="handleScroll"
      >
        <!-- for each message -->
        <div
          v-for="(message, i) in messages"
          :key="`message_${i}_room_${room.id}`"
        >
          <!-- gap between messages -->
          <div :class="msgGap(i)"></div>

          <v-row
            align="start"
            :justify="message.sender._id === myProfileId ? 'end' : 'start'"
          >
            <Message
              :message="message"
              :prevMsg="getPrevMsg(i)"
              :nextMsg="getNextMsg(i)"
              :index="i"
              :maxWidth="100"
              :streaming="annaStreaming && message.STREAMING"
              :messagesLength="messages.length"
              @setReference="$emit('setReference', $event)"
            />
          </v-row>
        </div>
      </v-container>
    </v-container>
    
    <div :style="`position: sticky; z-index: 2; top: ${innerTop}px;`">
      <v-row 
        justify="start"
        align="end"
        class="px-8"
        style="position: relative;"
      >
        <span :class="{
          'grey--text text--darken-1 font-weight-medium body-2 mr-1': annaThinking,
          'transparent--text body-2': !annaThinking
        }">
          Anna is thinking
        </span>
        <v-progress-linear
          v-if="annaThinking"
          stream
          color="grey darken-1"
          buffer-value="0"
          style="width: 10%;"  
        />
      </v-row>

      <TextArea
        :lighterViewerOn="true"
        :label="'Message...'"
        :loading="loading"
        :autoCall="autoCall"
        :menuClass="menuClass"
        :locked="annaStreaming"
        :streaming="annaStreaming"
        @stop="stopStreaming = true"
        @submit="sendMsg"
      ></TextArea>
    </div>
  </div>
</template>

<script>
import Message from './Message.vue'
import TextArea from './TextArea'
import { SnackBar } from '@components/App'
import { mdiArrowDown, mdiSend } from '@mdi/js'
import { replaceLatexDelimiters as formatLatex } from '@utils'
import scroll_bottom from '@directives/scroll-bottom'
import { MediaURL } from '@components'
import ChatFunctions from './ChatFunctions.vue'

import { mapGetters } from 'vuex'


import API from '@api'

const { VUE_APP_LANGCHAIN_API } = process.env

export default {
  components: {
    SnackBar,
    TextArea,
    Message
  },

  directives: {
    scroll_bottom
  },

  props: {
    appChamber: {
      type: Boolean,
      default: false
    },
    fetchContext: {
      type: Boolean,
      default: false
    },
    containerClass: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 500
    },
    maxWidth: {
      type: Number,
      default: 60
    },
    room: {
      type: Object,
      default() {
        return {}
      }
    },
    menuClass: {
      type: String,
      default: ''
    },
    outerHeight: {
      type: Number,
      default: 80
    },
    innerTop: {
      type: Number,
      default: 80
    },
    langChain: {
      type: Boolean,
      default: false
    },
    autoCall: {
      type: Boolean,
      default: false
    },
    filename: {
      type: String,
      default: ''
    }
  },

  async created() {
    try {
      // SETS GLOBAL CHATFUNCTIONS
      this.CALL_ANNA = ChatFunctions.callAnna.bind(this);
      this.getMediaUrl = MediaURL.getMediaUrl.bind(this);

      setTimeout(() => {
        this.loading = false
        setTimeout(() => {
          this.scrollBottom()
          this.smoothScroll = true
        })
      }, 300)

    } catch (err) {
    
      console.error(err)
    
    }
  },

  data: () => ({
    mdiArrowDown,
    mdiSend,

    myProfileId: '123456789',

    contents: [],

    loading: true,

    snackOn: false,
    snackMsg: '',

    annaThinking: false,
    annaStreaming: false,
    stopStreaming: false,

    scrollCounter: 0,
    smoothScroll: false,

    messages: [],
    viewport: {},
    host: {},
    anna: {},

    showScrollDown: false,
    stickyBottom: true
  }),

  computed: {
    ...mapGetters({
      context: 'context'
    }),

    scrollDownColor() {
      return this.$vuetify.theme.dark ? '' : 'primary'
    },

    sender() {
      return {
        _id: this.myProfileId,
        name: 'Student',
        username: 'student',
        avatar: ''
      }
    },

    annaSender() {
      return {
        _id: '484618161',
        name: 'Anna',
        username: 'anna',
        avatar: 'https://hisolver-files.s3.amazonaws.com/images/anna-2023-12-21.png'
      }
    }
  },

  watch: {
    messages: {
      deep: true,
      handler () {
        if (!this.showScrollDown)
          this.scrollBottom()
      }
    }
  },

  methods: {
    getPrevMsg(i) {
      return i > 0 ? this.messages[i - 1] : null;
    },

    getNextMsg(i) {
      return i < this.messages.length - 1 ? this.messages[i + 1] : null;
    },

    msgGap(index) {
      if (index > 0) {
        if (this.messages[index-1].sender._id != this.messages[index].sender._id)
          return 'mt-4 mb-1'
        else
          return 'my-1'
      }
    },

    replaceLatexDelimiters(str) {
      return formatLatex(str)
    },

    handleWheel (evt) {
      if (evt.deltaY < 0 && this.annaStreaming)
        this.stickyBottom = false
    },

    handleScroll() {
      const chatContainer = this.$refs.chatContainer
      const correctedScroll = chatContainer.scrollHeight - chatContainer.clientHeight
      this.showScrollDown = !this.loading && chatContainer.scrollTop < 0.98 * correctedScroll
    },

    scrollBottom(force = false) {
      if (force) this.stickyBottom = true

      if (this.stickyBottom) {
        const chatContainer = this.$refs.chatContainer
        const scrollHeight = chatContainer.scrollHeight;
        
        this.scrollCounter += 1
        if (
          scrollHeight > chatContainer.scrollTop  &&
          (chatContainer.scrollTop === 0 || force || this.scrollCounter > 20)
        ) {
          this.scrollCounter = 0
          
          if (chatContainer.scrollTop === 0)
            chatContainer.scrollTop = scrollHeight
          else
            requestAnimationFrame(() => {
              chatContainer.scrollTop = scrollHeight
            });
        }
      }
    },

    removeAnnaLinks(inputString) {
      // Use regex to replace <a> tags with innerHTML "@anna" with an empty string
      return inputString.replace(/<a [^>]*>@anna<\/a>/g, '');
    },

    async sendMsg({
      content,
      type = 'chat_msg'
    }) {
      const message = {
        type,
        sender: this.sender,
        // TODO: review why the fuck do we have this replaceLatexDelimiters here and in the message.vue component?
        content: this.replaceLatexDelimiters(content.replace(/\u2005/g, " ")),
        createdAt: (new Date()).toISOString(),
        ...(this.messages.length > 0 ?
          { previousMsg: this.messages[this.messages.length - 1].id } : {})
      }

      // important to have this first since
      // it will remove anna links in chamber chats before sending the message to the socket
      if(this.autoCall) message.content = this.removeAnnaLinks(message.content) // TODO: review this
      
      // Either by type or direct call
      // TODO: remove tools definition here
      // For now, the decision to call AI should be solely based on call type

      const memory = this.messages.slice(-10).map(message => {
        const role = message.sender.username === 'anna' ? 'assistant' : 'user';
        return {
          role,
          name: message.sender.name,
          content: message.content
        };
      }); // TODO: name users FOR ROOM CHATS AND CHATS WITH MULTIPLE PERSONAS

      this.messages.push(message)

      setTimeout(() => {
        // keep the scroll to the bottom (if it's already there)
        this.scrollBottom(!this.showScrollDown)
      }, 10)

      await this.CALL_ANNA(null, memory, 'Anna', null, type, message, null, null)
      
      setTimeout(() => {
        this.stickyBottom = false
        this.annaStreaming = false
      })
    }
  }
}
</script>