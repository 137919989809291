<template>
  <v-sheet
    :style="`max-width: ${maxWidth}%;
      border-radius: ${topLeftRadius} 13px ${bottomRightRadius} 13px;`"
    :color="getColorBasedOnSenderAndTheme"
    :class="getMessageBoxClass"
  >
    <v-container class="pa-1 pt-1 pb-1">
      <span
        v-if="showMsgSenderName"
        class="text-caption mx-2 grey--text text--darken-1"
      >
        ~{{ message.sender.name }}
      </span>
      <v-card-text class="pa-2 pt-1 pb-1">
        <v-fade-transition hide-on-leave>
          <div
            v-if="containsLaTeXOrMarkdown(message.content)"
            style="position: relative;"
            @mousemove="handleMouseMove"
            @mouseleave="handleMouseLeave"
          >
            <v-btn
              v-show="buttonVisible"
              icon
              :style="`background-color: ${
                $vuetify.theme.dark ?
                'rgba(100, 100, 100, 0.8);' :
                'rgba(238, 238, 238, 0.9);'
              }
              position: absolute;
              top: ${buttonTop}px;
              right: ${buttonRight}px;0
              z-index: 10`"
              :color="copied ? 'success': ''"
              @click="copyHtmlToClipboard(copyCode)"
            >
              <v-icon>
                {{ copied ? mdiCheck :  mdiClipboardOutline }}
              </v-icon>
            </v-btn>
            <!-- See https://github.com/KaTeX/KaTeX/issues/219 -->
            <div v-for="(content, i) in messageContent">
              <markdown-it-vue
                :key="`msgContent_${i}`"
                class="md-body"
                :content="`${content} ${
                  streaming && i === messageContent.length - 1 &&
                  content.slice(0,2) != '$$' && content.slice(0, 3) != '```' ?
                    `$\\LARGE \\textcolor{${$vuetify.theme.dark ? 'white' : '#757575'}}{●}$` : ''
                }`"
                :options="options"
              />

              <div class="my-3"></div>
            </div>

          </div>
        </v-fade-transition>

        <v-fade-transition hide-on-leave>
          <span v-if="!containsLaTeXOrMarkdown(message.content)">
            <span
              class="text-body-1"
              style="white-space:pre-wrap;"
              v-html="message.content"
            ></span>
            <v-btn v-if="streaming" icon small>
              <v-icon small>
                {{ mdiCheckboxBlankCircle }}
              </v-icon>
            </v-btn>
          </span>
        </v-fade-transition>
        
        <span>
          <v-fade-transition hide-on-leave>
            <v-container
              v-if="message.imgUrl"
              class="px-0"
            >
              <v-card outlined class="hs-rounded-12">
                <v-img :src="message.imgUrl" />
              </v-card>              
            </v-container>
          </v-fade-transition>
          
          <!-- Source -->
          <v-fade-transition hide-on-leave>
            <v-container
              v-if="singleSource && message.sources && message.sources.length > 0"  
              class="px-0"
            >
              <span class="grey--text text--darken-1">Reference pages: </span>
              <span
                v-for="(source, index) in references"
                :key="`reference_${index}`"
              >
                <v-btn
                  small
                  icon
                  color="grey"
                  :class="{ 'v-btn--active amber--text text--accent-4': activeMsg(source) }"
                  @click="setReference(source)"
                >
                  {{ source.metadata.page + 1 }}
                </v-btn>
              </span>
            </v-container>
          </v-fade-transition>

          <!-- Sources -->
          <v-fade-transition hide-on-leave>
            <v-container
              v-if="!singleSource && message.sources && message.sources.length > 0"  
              class="px-0"
            >
              <span class="grey--text text--darken-1 font-weight-bold">
                {{ $t('references') }}
              </span>
              <br />
              <div
                v-for="(reference, refIdx) in references"
                :key="`reference_${refIdx}`"
              >
                <span
                  v-if="reference.title"
                  class="grey--text text--darken-1 text--truncate"
                >
                  {{ truncate(reference.title, 45) }}:
                </span>
                <span
                  v-for="(source, index) in reference.sources"
                  :key="`source_${index}`"
                >
                  <v-btn
                    small
                    icon
                    color="grey"
                    @click="setReference(source)"
                  >
                    {{ source.metadata.page + 1 }}
                  </v-btn>
                </span>
              </div>
            </v-container>
          </v-fade-transition>

          <!-- For multiple choice problems -->
          <v-fade-transition hide-on-leave>
            <v-container v-if="loadingSolution">
              <v-row 
                justify="start"
                align="center"
              >
                <span class="grey--text text--darken-1 font-weight-medium body-2 mr-1">
                  Loading choices
                </span>
                <v-progress-linear
                  stream
                  color="grey darken-1"
                  buffer-value="0"
                  style="width: 10%;"  
                />
              </v-row>
            </v-container>
          </v-fade-transition>

          <v-fade-transition hide-on-leave>
            <v-container v-if="!loadingSolution && message.solution">
              <v-row
                v-for="(choice, j) in message.solution.choices"
                :key="`choice_${j}_message_${message.id}`"
                justify="start"
                align="end"
                class="py-1"
              >
                <v-sheet
                  :style="`border-radius: 12px;
                  background-color: ${ $vuetify.theme.dark ? '#424242' : '#F5F5F5' };
                  width: 80%;`"
                  v-ripple
                  class="clickable"
                  @click="selectChoice(choice, message.content, message.solution)"
                >
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <markdown-it-vue
                        :content="_replaceLatexDelimiters(choice)"
                        :options="options"
                      />
                    </v-list-item-content>

                    <v-list-item-action class="my-0">
                      <v-btn icon small>
                        <v-icon small>
                          {{ mdiSend }}
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-sheet>            
              </v-row>
            </v-container>
          </v-fade-transition>

          <span
            v-if="message.sender._id === myProfileId"
            :class="{
              'grey--text': true,
              'text--lighten-1': $vuetify.theme.dark,
              'text--darken-1': !$vuetify.theme.dark
            }"
            style="bottom: 0; right: 0; padding: 2px; font-size: 9px;"
          >
            {{ getTime(message.createdAt) }}
          </span>
        </span>
      </v-card-text>

      <!-- If the message.sender._id is equal to myProfileId, dont show the following  v-card-actions  -->
      <v-card-actions
        v-if="showMsgAction"
        class="pa-0"
      >
        <v-list-item>                    
          <v-list-item-avatar 
            size="30"
            color="grey"
          > 
            <v-img :src="message.sender.avatar" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              v-if="message.sender.username != 'anna'"
              class="grey--text text--darken-1"
            >
              {{ message.sender.name }}
            </v-list-item-title>
            <v-list-item-title
              v-if="message.sender.username === 'anna'"
              class="grey--text text--darken-1 font-weight-bold"
            >
              {{ message.sender.name }}
            </v-list-item-title>
          </v-list-item-content>

          <div class="mx-2"></div>

          <v-list-item-action>
            <v-row align="center">
              <div
                v-if="message.sender._id != myProfileId"
                style="bottom: 0; right: 0; padding: 3px; font-size: 9px; color: grey;"
              >
                {{ getTime(message.createdAt) }}
              </div>

              <!-- Use this to highlight message to the context -->
              <v-btn
                small 
                icon
                color="amber accent-4"
                v-if="insight"
              >
                <v-icon small>
                  {{ mdiStarFourPoints }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </v-card-actions>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { parseISO, format } from 'date-fns'
import {
  latexOptions,
  stripHtml,
  truncateStr,
  containsLaTeXOrMarkdown,
  replaceLatexDelimiters,
  splitLatex,
  splitMarkdownCode
} from '@utils'
import { uniqBy } from 'lodash'
import MarkdownItVue from 'markdown-it-vue/src/index.js'
import 'markdown-it-vue/dist/markdown-it-vue.css'
import {
  mdiStarFourPoints,
  mdiClipboardOutline,
  mdiCheckboxBlankCircle,
  mdiSend,
  mdiCheck
} from '@mdi/js'

import API from '@api'

export default {
  components: {
    MarkdownItVue
  },

  props: {
    insight: {
      type: Boolean,
      default: false
    },

    streaming: {
      type: Boolean,
      default: false
    },
    // message object content
    message: {
      required: true,
      type: Object
    },
    // previous message object content
    prevMsg: {
      default: null,
      type: Object
    },
    // next message object content
    nextMsg: {
      default: null,
      type: Object
    },
    // Index of the current message in the array
    index: {
      required: true,
      type: Number
    },
    singleSource: {
      type: Boolean,
      default: false
    },
    messagesLength: {
      required: true,
      type: Number
    },
    maxWidth: {
      type: Number,
      default () {
        return this.$vuetify.breakpoint.xl ? 60 : 80
      }
    },
    loadingSolution: {
      type: Boolean,
      default: false
    }
  },
  
  data: () => ({
    mdiSend,
    mdiCheck,
    mdiCheckboxBlankCircle,
    mdiClipboardOutline,
    mdiStarFourPoints,

    myProfileId: '123456789',


    latexOptions,
    options: {
      markdownIt: {
        linkify: true,
        html: true
      },
      katex: {
        throwOnError: false,
        errorColor: '#cc0000',
      },
      linkAttributes: {
        attrs: {
          target: '_blank',
          rel: 'noopener'
        }
      }
    },
    buttonVisible: false,
    buttonTop: 0,
    buttonRight: 0,
    copyCode: '',
    copied: false
  }),

  computed: {
    ...mapGetters({
      currentMsg: 'conversation/currentMsg',
      currentPage: 'pdf/currentPage'
    }),

    messageContent() {
      const processedLatex = this._replaceLatexDelimiters(this.message.content)
      const latexArray = splitLatex(processedLatex)
      const finalArray = []

      latexArray.forEach(latexStr => {
        finalArray.push(...splitMarkdownCode(latexStr))
      })

      return finalArray
    },

    references () {
      if (this.message.sources && this.message.sources.length > 0) {
        const uniqSourcePgs = uniqBy(this.message.sources.map(str => typeof str === 'string' ? JSON.parse(str) : str), 'metadata.page')
        if (this.singleSource) return uniqSourcePgs

        let groupBy = (array, property) => {
          const grouped = [];
          const lookup = {};

          array.forEach(obj => {
            if(!obj.hasOwnProperty(property)) {
              return; 
            }

            const key = obj[property];
            if(!lookup[key]) {
              lookup[key] = {
                [property]: key,
                sources: []
              };
              grouped.push(lookup[key]);
            }

            lookup[key].sources.push(obj);
          });

          return grouped;
        }
        
        return groupBy(uniqSourcePgs, 'title')

      } else return []   
    },

    showMsgSenderName() {
      const isNextSenderSame = this.nextMsg && this.nextMsg.sender._id === this.message.sender._id;
      const isPrevSenderSame = this.prevMsg && this.prevMsg.sender._id !== this.message.sender._id;

      return this.message.sender._id != this.myProfileId &&
        ((!this.prevMsg && isNextSenderSame) || (isNextSenderSame && isPrevSenderSame))
    },

    showMsgAction() {
      return this.message.sender._id != this.myProfileId  &&
      (!this.nextMsg || this.nextMsg.sender._id != this.message.sender._id)
    },

    bottomRightRadius() {
      if (
        this.message.sender._id === this.myProfileId &&
        (!this.nextMsg || this.nextMsg.sender._id !== this.myProfileId)
      )
        return '10px'
      else
        return '13px'
    },
    
    topLeftRadius() {
      if (
        this.message.sender._id !== this.myProfileId &&
        (!this.prevMsg || this.message.sender._id !== this.prevMsg.sender._id)
      )
        return '10px'
      else
        return '13px'
    },

    // message color
    getColorBasedOnSenderAndTheme() {
        const isMyMessage = (this.message.sender._id === this.myProfileId)
        const annaMessage = (this.message.sender.username == 'anna')

        if (isMyMessage)
          return this.$vuetify.theme.dark ? 'deep-purple darken-1' : '#e0ddfbd9'
        else if (annaMessage)
          return this.$vuetify.theme.dark ? '#171717' : 'white'
        else
          return this.$vuetify.theme.dark ? '#171717' : 'white'
    },

    // the tail of the message box
    getMessageBoxClass() {
        const isMyMessage = (this.message.sender._id === this.myProfileId)

        if (isMyMessage) {
          const lastOfConsecutive = (!this.nextMsg || this.nextMsg.sender._id !== this.myProfileId);
          if (lastOfConsecutive)
            return this.$vuetify.theme.dark ? 'message-box-right-dark' : 'message-box-right-light'
          return
        }

        if (this.prevMsg && this.prevMsg.sender._id === this.message.sender._id)
          return 

        return this.$vuetify.theme.dark ? 'message-box-left-dark' : 'message-box-left-light';
    },
  },

  methods: {
    ...mapMutations({
      SET_ANNA_CHAMBER: 'setAnnaChamber',
      SET_CURRENT_MSG: 'conversation/setCurrentMsg',
      SET_SOURCES: 'pdf/setSources',
      SET_CURRENT_PAGE: 'pdf/currentPage',
      SET_PAGINATOR: 'pdf/paginatorOn'
    }),

    selectChoice(choice, question, solution) {
      this.$emit('choose', { choice, question, solution })
    },

    containsLaTeXOrMarkdown(str) {
      // imported from utils
      return containsLaTeXOrMarkdown(str)
    },

    _replaceLatexDelimiters(str) {
      // imported from utils
      return replaceLatexDelimiters(str)
    },

    truncate(str = '', num = 200) {
      return truncateStr(str, num)
    },

    activeMsg(source) {
      return this.currentMsg === this.message._id &&
      this.currentPage === source.metadata.page + 1
    },

    async setReference(source) {

      return;
      this.SET_CURRENT_MSG(this.message._id)
      this.SET_SOURCES([source])

      const page = source.metadata.page + 1

      if(this.$route.name === 'pdf.read')
        this.$emit('setReference', page)
      else {
        try {
          const pdfId = source.metadata.source.split('/').pop().split('.')[0]
          const [content] = await API().get(`contents/${this.user.id}`, {
            params: {
              media: pdfId
            }
          })
          this.SET_ANNA_CHAMBER()
          this.$router.push(`pdf/read?id=${content.id}&page=${page}`)
        } catch (err) {}         
      }
        
    },
    
    getTime(isoString) {
      let messagedate = parseISO(isoString)
      // return ''
      // TODO: if format() is used, the new message is not visible unless refreshed
      return format(messagedate, 'h:mm aa')
    },

    /*
    * The following functions take care of the copying of code blocks
    */
   
    // Calculates vertical offset from an element to its offset parent for positioning
    getPositionRelativeToOffsetParent(element) {
      const elementRect = element.getBoundingClientRect();
      const offsetParentRect = element.offsetParent.getBoundingClientRect();
      return elementRect.top - offsetParentRect.top; 
    },

    handleMouseLeave() {
      if (!this.copied) this.buttonVisible = false;
      this.copyCode = ''
    },

    copyHtmlToClipboard(data) {
      var blob;
      if (typeof data === 'object') {
        blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
      } else {
        blob = new Blob([data], { type: 'text/plain' });
      }
      
      navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob
        })
      ])
      .then(() => {
        this.copied = true
        setTimeout(() => {
          this.copied = false
          this.buttonVisible = false
        }, 2000)
      })
      .catch((error) => {
        console.error('Error copying data to clipboard:', error);
      });
    },

    // Handles mouse movement to show a copy button over code blocks
    handleMouseMove(event) {
      const x = event.clientX; // Mouse X coordinate
      const y = event.clientY; // Mouse Y coordinate
      let elementUnderMouse = document.elementFromPoint(x, y); // Element under mouse cursor

      /*
      * Check if the element or its parent is a code block
      * The logic is that we only want the clipboard to be activated on top of
      * proper code and it must be deactivated when the mouse leaves the
      * codeblock (even if it is still inside the markdown-body div)
      * Also the button visible condition catches the case when
      * the mouse is hovering the clipboard button
      */
      if (
        elementUnderMouse && !elementUnderMouse.classList.contains('markdown-body') &&
        (this.buttonVisible ||
        elementUnderMouse.matches('pre.hljs') ||
        elementUnderMouse.parentElement.matches('pre.hljs') ||
        elementUnderMouse.parentElement.parentElement.matches('pre.hljs'))
      ) {
        if (!this.buttonVisible) {
          // Target the code block element directly
          elementUnderMouse = elementUnderMouse.matches('pre.hljs') ? elementUnderMouse :
            elementUnderMouse.parentElement.matches('pre.hljs') ?
              elementUnderMouse.parentElement :
              elementUnderMouse.parentElement.parentElement

          /*
          * Set the code for copying.
          * innerText is the option that worked best.
          * An edge case that still haunts this system is that of a pure html code.
          * Because we pre process the paste event to change multi line pasting
          * the html pasted is interpreted as actual html instead of content.
          * Hacky but mostly works...
          */
          this.copyCode = elementUnderMouse.innerText

          // Position the copy button near the code block
          this.buttonTop = 10 + this.getPositionRelativeToOffsetParent(elementUnderMouse);
          this.buttonRight = 10;
          // Make the copy button visible
          this.buttonVisible = true;
        }
      } else {
        // Hide the copy button when not over a code block
        this.buttonVisible = false;
        this.copyCode = '';
      }
    },

    copyContent(htmlStr) {
      this.copied = true
      setTimeout(() => {
        this.copied = false
        this.buttonVisible = false
      }, 2000)
      return this.$copyText(stripHtml(htmlStr))
    },
  }
}
</script>

<style scoped>
.message-box-left-light {
  position: relative;
}

.message-box-left-light::before {
  content: "";
  position: absolute;
  top: 5px; /* position of the tail vertically */
  right: 99.5%; /* position of the tail horizontally */
  border-width: 0 13px 10px 0; /* half size of the tail */
  border-style: solid;
  border-color: transparent rgb(255, 255, 255) transparent transparent; /* adjust this color to match your message box */
}

.message-box-right-light {
  position: relative;
}

.message-box-right-light::before {
  content: "";
  position: absolute;
  bottom: 5px; /* position of the tail vertically */
  left: 99%; /* position of the tail horizontally */
  border-width: 0 13px 10px 0; /* half size of the tail */
  border-style: solid;
  border-color: transparent transparent #e0ddfbd9  transparent;
}

.message-box-left-dark {
  position: relative;
}

.message-box-left-dark::before {
  content: "";
  position: absolute;
  top: 5px; /* position of the tail vertically */
  right: 99.5%; /* position of the tail horizontally */
  border-width: 0 13px 10px 0; /* half size of the tail */
  border-style: solid;
  border-color: transparent #171717 transparent transparent; /* adjust this color to match your message box */
}

.message-box-right-dark {
  position: relative;
}

.message-box-right-dark::before {
  content: "";
  position: absolute;
  bottom: 5px; /* position of the tail vertically */
  left: 99.5%; /* position of the tail horizontally */
  border-width: 0 15px 10px 0; /* half size of the tail */
  border-style: solid;
  border-color: transparent transparent #5e35b1  transparent; /* adjust this color to match your message box */
}


.time-stamp {
  position: absolute; 
  bottom: 0; 
  right: 0; 
  padding: 10px; 
  font-size: 8px; 
  color: grey;
}
</style>