<template>
  <div>
    <Toolbar />

    <v-main>
      <div :style="`${backgroundColor}`">
        <v-row justify="center" align="start">
          <v-col cols="7">
            <slot></slot>
          </v-col>

          <v-col :cols="sideCols">
            <v-slide-x-transition hide-on-leave>
              <ContentIntro
                v-if="!showChat"
                @openChat="OPEN_CHAT(!showChat)"  
              ></ContentIntro>
            </v-slide-x-transition>

            <v-slide-x-transition hide-on-leave>
              <v-card v-if="showChat"  style="border-radius: 30px;" elevation="0">
                <ChatContainer
                  containerClass="pt-4 pr-4"
                  :style="`background-color: ${backgroundColor} !important; border-radius: 20px;`"
                  :height="containerHeight"
                  :outerHeight="outerHeight + 10"
                  :innerTop="containerHeight - 10"
                  menuClass="menu--top"
                  :fetchContext="true"
                  :autoCall="true"
                  :appChamber="true"
                />
              </v-card>
            </v-slide-x-transition>
          </v-col>
        </v-row>
      </div>
    </v-main>
  </div>
</template>

<script>
import { ChatContainer } from '@components/Conversation'
import ContentIntro from '@components/Content/ContentIntro'
import Toolbar from '@components/App/Toolbar'
import { mapGetters, mapMutations } from 'vuex'
import API from '@api'

export default {
  components: {
    ContentIntro,
    ChatContainer,
    Toolbar
  },

  async created() {
    if (!this.user) return;
  },

  mounted() {
    window.addEventListener('resize', () => {
      this.innerWidth = window.innerWidth
    })

    this.innerWidth = window.innerWidth
  },

  data() {
    return {
      innerWidth: 0,
      loading: true,
      currentConversation: {},
      conversations: [],
    }
  },

  sockets: {
    notification: {
      connect() {
        this.$socket.notification.emit('saveSocket', {
          profile: this.profile
        })
        
        this.$socket.notification.emit('loadNotifications', {
          senderSocket: this.$socket.notification.id,
          profile: this.profile
        })
      },

      notifications(notifications) {
        this.SET_NOTIFICATIONS(notifications)
      },

      newNotification(notification) {
        this.ADD_NOTIFICATION(notification)
      }
    }
  },

  computed: {
    ...mapGetters({
      showChat: 'showChat',
      innerHeight: 'innerHeight',
      user: 'user',
      profile: 'profile/id',
      loggingOut: 'loggingOut',
      from: 'from',
      darkTheme: 'darkTheme',
      locale: 'locale',
      anna: 'anna'
    }),

    sideCols() {
      return this.showChat ? 4 : this.innerWidth > 1380 ? 3 : 4 
    },

    containerHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight))
    },

    outerHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight))
    },

    rootHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight))
    },

    backgroundColor() {
      return this.$vuetify.theme.dark ? '' : 'background-color: #F5F5F5'
    },

    pdfPage() {
      return this.$route.name === 'pdf.read'
    },

    background() {
      return !this.$vuetify.theme.dark
        ? 'backgroundWhite'
        : ''
    }
  },

  methods: {
    ...mapMutations({
      OPEN_CHAT: 'OPEN_CHAT'
    }),

    async setChamber() {
      this.loading = true
      // This might turn undefined without the Boolean cast
      const isPersonaActive = Boolean(this.currentPersona && this.currentPersona.id)

      // If there is a persona selected, we set a room only for the persona
      let [chamber] = await API().get(`rooms/${this.user.id}`, {
        params: {
          query: {
            type: {
              $eq: 'CHAMBER'
            },
            owner: {
              $eq: this.myProfileId
            },
            ...(isPersonaActive ? { persona: { $eq: this.currentPersona.id } } : {})
          }
        }
      })

      if (!chamber) {
        chamber = await API().post(`room/${this.user.id}`, {
          owner: this.myProfileId,
          ...(isPersonaActive ? { persona: this.currentPersona.id } : {}),
          type: 'CHAMBER',
          name: isPersonaActive ?
            `Persona ${this.currentPersona.name} Chamber for ${this.myName}` :
            `Chamber for ${this.myName}`
        })
      }

      this.SET_CURRENT_ROOM(chamber)

      this.conversations = chamber && chamber.id ?
        await API().get(`conversations/${this.user.id}`, {
          params: {
            query: {
              room: {
                $eq: chamber.id
              }
            },
            sort: {
              createdAt: '-1'
            }
          }
        }) : []

      if (this.conversations.length === 0) {
        this.conversations.unshift(await API().post(`conversation/${this.user.id}`, {
          room: chamber.id,
          name: `${this.myName} and Anna's convo 1`
        }))
      }

      this.currentConversation = this.conversations[0]
      
      setTimeout(() => {
        // Use the timeout to wait until the chamber is created
        // in case this is the first chat with a persona
        this.loading = false
      }, 200)
    },


    setConversation(conversation) {
      this.currentConversation = conversation
      this.loading = true
      setTimeout(() => {
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>
/*
  Sets the height of the image skeleton loader to 100% of the parent component.
*/
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading .v-skeleton-loader__image {
  height: 100% !important;
}

/*
  Sets the height and width of the avatar skeleton loader to 100% of the parent component.
*/
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading .v-skeleton-loader__avatar {
  height: 100% !important;
  width: 100% !important;
}

/*
  Stops autocomplete and combobox icons from rotating.
  There might be an option to set this within Vuetify 3.
*/
::v-deep .v-select.v-select--is-menu-active
  .v-input__icon--append
  .v-icon {
  transform: rotate(0) !important;
}
</style>