export default {
  namespaced: true,

  state: {
    currentRoom: {}
  },

  getters: {
    currentRoom: ({ currentRoom }) => currentRoom
  },
  
  mutations: {
    setCurrentRoom: (state, payload) => {
      state.currentRoom = payload
    }
  }
}