<template>
  <v-snackbar
    app
    :top="snackTop"
    :color="color"
    v-model="snack"
  >
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        color="white"
        v-bind="attrs"
        @click="$emit('close')"
      >
        OK
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    snackOn: {
      type: Boolean,
      required: true
    },
    snackMsg: {
      type: String,
      required: true
    },
    snackTop: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      snack: false,
      messages: {
        waitlistConfirmed: 'primary',
        signinCleanCookies: 'primary',
        accountDeactivated: 'primary',
        accountReactivated: 'primary',
        mobileSoon: 'primary',
        noMatch: 'red darken-4',
        videoUpdated: 'primary',
        youtubeNotFound: 'red darken-4',
        youtubeOnly: 'red darken-4',
        youtubeExists: 'primary',
        signupEmailSent: 'primary',
        signupErr: 'red darken-4',
        emailDuplicate: 'red darken-4',
        emailVerificationErr: 'red darken-4',
        expiredEmailToken: 'red darken-4',
        errEmailToken: 'red darken-4',
        expiredPwdToken: 'red darken-4',
        errPwdToken: 'red darken-4',
        pwdResetEmailSent: 'primary',
        badImg: 'red darken-4',
        errServer: 'red darken-4',
        invalidCredentials: 'red darken-4',
        noCookies: 'primary',
        sessionExpired: 'primary',
        errPwd: 'red darken-4',
        pwdChanged: 'primary',
        emailValidationSent: 'primary',
        emailChanged: 'primary',
        incomplete: 'red darken-4',
        groupNotFound: 'red darken-4',
        groupUrlCopied: 'primary',
        noFriendshipsNoGroup: 'red darken-4',
        newGroupInvitation: 'primary',
        urlNotCopied: 'red darken-4',
        invitationsSuccess: 'primary',
        invitationSuccess: 'primary',
        newMembership: 'primary',
        newMember: 'primary',
        invitationRefused: 'primary',
        membershipDeactivated: 'primary',
        membershipReenter: 'primary',
        membershipAccepted: 'primary',
        memberAccepted: 'primary',
        membershipDeclined: 'primary',
        profileUrlCopied: 'primary',
        profileUrlNotCopied: 'red darken-4',
        hisolverUrlCopied: 'primary',
        friendshipRequestSent: 'primary',
        duplicateFriendship: 'red darken-4',
        friendshipRequestAccepted: 'primary',
        friendshipRequestDeclined: 'primary',
        unauthorized: 'red darken-4',
        usernameTaken: 'red darken-4',
        invalidFields: 'red darken-4',
        permissionsChanged: 'primary',
        notebookCoverFields: 'primary',
        notebookSaveEditor: 'primary',
        notebookNoSavePermission: 'danger',
        largePdf: 'danger',
        demoUrlCopied: 'primary',
        manimErr: 'danger',
        chatNotFound: 'danger',
        fieldMissing: 'danger',
        shortDescription: 'danger',
        rolesMissing: 'danger',
        shareLinkCopied: 'primary'
      }
    }
  },

  watch: {
    snackOn(v) {
      this.snack = v
      if (v) {
        setTimeout(() => {
          this.$emit('close')
        }, 10000)
      }
    }
  },

  computed: {
    message() {
      return this.snackMsg
        ? this.$t(`snackbar.${this.snackMsg}`) 
        : ''
    },
    color() {
      return this.snackMsg
        ? this.messages[this.snackMsg]
        : 'primary'
    }
  }
}
</script>

<style>
.v-snack__wrapper.v-sheet {
  border-radius: 12px !important;
}
</style>