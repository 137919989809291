import Vue from 'vue'
import VueSocketIO from '@hisolver/vue-socket.io'

const {
  VUE_APP_SOCKETIO_DEBUG,
  VUE_APP_LOCALHOST,
  NODE_ENV
} = process.env

const baseURL = NODE_ENV != 'production'
  ? `http://${VUE_APP_LOCALHOST}:3000`
  : process.env.VUE_APP_SERVER_URI

const namespaces = [
  'conversation',
  'notification',
  'studySession',
  'onlineSession'
]

namespaces.forEach(namespace => {
  Vue.use(new VueSocketIO({
    debug: VUE_APP_SOCKETIO_DEBUG === 'true',
    connection: `${baseURL}/${namespace}`,
    options: {
      namespaceName: namespace,
      useConnectionNamespace: true,
      autoConnect: false
    }
  }))
})