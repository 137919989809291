import API from '@api'

const s3Url = (params, file) => {
  return new Promise((resolve, reject) => {
    API().get('/s3/signedUrl', { params })
      .then(data => {
        const { uploadUrl } = data
        const { contentType } = params
        const binary = atob(file.split(',')[1])
        const array = []
        
        for (let i = 0; i < binary.length; i++){

          array.push(binary.charCodeAt(i))
        
        }

        const type = { type: contentType }
        const uint8Array = [new Uint8Array(array)]
        const blobData = new Blob(uint8Array, type)

        fetch(uploadUrl, {
          method: 'PUT',
          body: blobData,
          headers: {
            "x-amz-acl": "public-read"
          }
        })
        .then(resolve)
        .catch(reject)
      })
      .catch(reject)
  })
}

export default s3Url