export default {
  namespaced: true,

  state: {
    id: '',
    isValid: false,
    isActive: true,
    friendshipCount: 0,
    name: '',
    bio: '',
    avatar: '',
    username: '',
  },

  getters: {
    id: state => {
      return state.id
    },
    isValid: state => {
      return state.isValid
    },
    isActive: state => {
      return state.isActive
    },
    bio: state => {
      return state.bio
    },
    name: state => {
      return state.name
    },
    username: state => {
      return state.username
    },
    avatar: state => {
      return state.avatar
    },
    friendshipCount: state => {
      return state.friendshipCount
    }
  },

  mutations:  {
    id: (state, payload) => {
      state.id = payload
    },
    isValid: (state, payload) => {
      state.isValid = payload
    },
    isActive: (state, payload) => {
      state.isActive = payload
    },
    name: (state, payload) => {
      state.name = payload
    },
    username: (state, payload) => {
      state.username = payload
    },
    avatar: (state, payload) => {
      state.avatar = payload
    },
    bio: (state, payload) => {
      state.bio = payload
    },
    friendshipCount: (state, payload) => {
      state.friendshipCount = payload
    }
  },

  actions: {
    set: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        try {
          commit('id', payload.id)
          commit('bio', payload.bio)
          commit('name', payload.name)
          commit('avatar', payload.avatar)
          commit('username', payload.username)
          commit('friendshipCount', payload.friendshipCount)
          commit('isValid', payload.isValid)
          commit('isActive', payload.isActive)
          resolve()
        } catch (error) {
          reject(error)
        }                
      })
    }
  }
}