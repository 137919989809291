import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify)

import pt from 'vuetify/es5/locale/pt';
import en from 'vuetify/es5/locale/en';
import { BACKGROUND_DARK_COLOR } from '@utils/constants'

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#4A148C",
        secondary: "#6A1B9A",
        tertiary: "#FFAB00",
        accent: "#FFFFFF", // KaTex compatibility for accent class on light mode
        error: "#F55A4E",
        info: "#00D3EE",
        success: "#5CB860",
        warning: "#FFA21A",
        danger: "#961717"
      },
      dark: {
        primary: "#8348ca",
        secondary: "#7532c6",
        tertiary: "#FFAB00",
        accent: BACKGROUND_DARK_COLOR, // KaTex compatibility for accent class on dark mode
        error: "#F55A4E",
        info: "#00D3EE",
        success: "#5CB860",
        warning: "#FFA21A",
        danger: "#961717"
      }
    },
  },
  
  lang: {
    locales: { pt, en },
    current: 'en'
  }
});
